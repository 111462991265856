import Vue from 'vue'
import Router from 'vue-router'
import store from "../store";

// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer')

// Views
const Dashboard = () => import('@/views/customer_service/Dashboard')

// Views - Pages
const Page404 = () => import('@/views/Page404')
const Login = () => import('@/views/Login')

// Users
const ChangePassword = () => import('@/views/customer_service/users/ChangePassword')
const SetPassword = () => import('@/views/customer_service/users/SetPassword')
const UserCreate = () => import('@/views/customer_service/users/UserCreate')
const UserEdit = () => import('@/views/customer_service/users/UserEdit')
const Users = () => import('@/views/customer_service/users/Users')
const User = () => import('@/views/customer_service/users/User')

const Customers = () => import('@/views/customer_service/customers/Customers')
const Customer = () => import('@/views/customer_service/customers/Customer')

const Vets = () => import('@/views/customer_service/vets/Vets')
const Vet = () => import('@/views/customer_service/vets/Vet')
const VetOnboardings = () => import('@/views/customer_service/vets/VetOnboardings')
const VetOnboarding = () => import('@/views/customer_service/vets/VetOnboarding')

const Policies = () => import('@/views/customer_service/policy/Policies')
const Policy = () => import('@/views/customer_service/policy/Policy')

const Quotes = () => import('@/views/customer_service/quote/Quotes')
const Quote = () => import('@/views/customer_service/quote/Quote')

const Claims = () => import('@/views/customer_service/claims/Claims')
const Claim = () => import('@/views/customer_service/claims/Claim')

const Complaints = () => import('@/views/customer_service/complaints/Complaints')
const Complaint = () => import('@/views/customer_service/complaints/Complaint')
const ComplaintEdit = () => import('@/views/customer_service/complaints/ComplaintEdit')

const Workflows = () => import('@/views/customer_service/workflow/Workflows')
const Workflow = () => import('@/views/customer_service/workflow/Workflow')
const WorkflowEdit = () => import('@/views/customer_service/workflow/WorkflowEdit')

const Payments = () => import('@/views/customer_service/payment/Payments')
const Payment = () => import('@/views/customer_service/payment/Payment')
const PaymentEdit = () => import('@/views/customer_service/payment/PaymentEdit')

const MTANS = () => import('@/views/customer_service/mta/MTANS')
const MTAHH = () => import('@/views/customer_service/mta/MTAHH')

const Search = () => import('@/views/customer_service/Search')
const SearchCustomer = () => import('@/views/customer_service/search/customer')
const SearchComplaint = () => import('@/views/customer_service/search/complaint')
const SearchVet = () => import('@/views/customer_service/search/vet')
const SearchClaim = () => import('@/views/customer_service/search/claim')
const SearchPolicy = () => import('@/views/customer_service/search/policy')

const CustomerService = () => import('@/views/customer_service/CustomerService')

const Export = () => import('@/views/customer_service/Reporting/Export')
const Import = () => import('@/views/customer_service/Reporting/Import')

const GDPRCleanse = () => import('@/views/customer_service/gdpr/GDPRCleanse')
const GDPRAudits = () => import('@/views/customer_service/gdpr/GDPRAudits')
const GDPRAudit = () => import('@/views/customer_service/gdpr/GDPRAudit')

const Tasks = () => import('@/views/customer_service/task/Tasks')
const MyTasks = () => import('@/views/customer_service/task/MyTasks')
const Task = () => import('@/views/customer_service/task/Task')
const TaskEdit = () => import('@/views/customer_service/task/TaskEdit')

// System Config

const Letters = () => import('@/views/system_config/Letters')
const Documents = () => import('@/views/system_config/Documents')
const ClaimDocuments = () => import('@/views/system_config/ClaimDocuments')
const Exclusions = () => import('@/views/system_config/Exclusions')
const Exclusion = () => import('@/views/system_config/Exclusion')

const Products = () => import('@/views/system_config/product/Products')
const Product = () => import('@/views/system_config/product/Product')

const Insurers = () => import('@/views/system_config/insurer/Insurers')
const Insurer = () => import('@/views/system_config/insurer/Insurer')
const InsurerEdit = () => import('@/views/system_config/insurer/InsurerEdit')
const InsurerSettingEdit = () => import('@/views/system_config/insurer/setting/InsurerSettingEdit')
const InsurerSettingAdd = () => import('@/views/system_config/insurer/setting/InsurerSettingAdd')

const Addons = () => import('@/views/system_config/addon/Addons')
const Addon = () => import('@/views/system_config/addon/Addon')
const AddonEdit = () => import('@/views/system_config/addon/AddonEdit')
const AddonAdd = () => import('@/views/system_config/addon/AddonAdd')

const Endorsements = () => import('@/views/system_config/endorsement/Endorsements')
const Endorsement = () => import('@/views/system_config/endorsement/Endorsement')
const EndorsementEdit = () => import('@/views/system_config/endorsement/EndorsementEdit')

const EligibilityQuestions = () => import('@/views/system_config/eligibility_question/EligibilityQuestions')
const EligibilityQuestion = () => import('@/views/system_config/eligibility_question/EligibilityQuestion')
const EligibilityQuestionEdit = () => import('@/views/system_config/eligibility_question/EligibilityQuestionEdit')

const Commissions = () => import('@/views/system_config/commission/Commissions')
const Commission = () => import('@/views/system_config/commission/Commission')
const CommissionEdit = () => import('@/views/system_config/commission/CommissionEdit')
const CommissionAdd = () => import('@/views/system_config/commission/CommissionAdd')

const Panels = () => import('@/views/system_config/commission/panels/Panels')
const Panel = () => import('@/views/system_config/commission/panels/Panel')
const PanelEdit = () => import('@/views/system_config/commission/panels/PanelEdit')
const PanelAdd = () => import('@/views/system_config/commission/panels/PanelAdd')

const SCEmailSettingCreate = () => import('@/views/system_config/email_setting/EmailSettingCreate')
const SCEmailSettingEdit = () => import('@/views/system_config/email_setting/EmailSettingEdit')
const SCEmailSettings = () => import('@/views/system_config/email_setting/EmailSettings')
const SCEmailSetting = () => import('@/views/system_config/email_setting/EmailSetting')

const RatesInsurers = () => import('@/views/system_config/rates/Insurers')
const Tables = () => import('@/views/system_config/rates/Tables')
const TableRates = () => import('@/views/system_config/rates/TableRates')
const Rate = () => import('@/views/system_config/rates/Rate')
const RateEdit = () => import('@/views/system_config/rates/RateEdit')

const TasksConfig = () => import('@/views/system_config/TasksConfig')
const TaskConfigEdit = () => import('@/views/system_config/TaskConfigEdit')

const QuestionSections = () => import('@/views/system_config/questions/QuestionSections')
const QuestionSection = () => import('@/views/system_config/questions/QuestionSection')

const PortalPopups = () => import('@/views/system_config/portal/PortalPopups')
const PortalPopup = () => import('@/views/system_config/portal/PortalPopup')
const PortalPages = () => import('@/views/system_config/portal/PortalPages')
const PortalPage = () => import('@/views/system_config/portal/PortalPage')
const PortalStyles = () => import('@/views/system_config/portal/PortalStyles')
const PortalStyle = () => import('@/views/system_config/portal/PortalStyle')

const SCSetPassword = () => import('@/views/system_config/users/SetPassword')
const SCUserCreate = () => import('@/views/system_config/users/UserCreate')
const SCUserEdit = () => import('@/views/system_config/users/UserEdit')
const SCUsers = () => import('@/views/system_config/users/Users')
const SCUser = () => import('@/views/system_config/users/User')

/*const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}*/

Vue.use(Router)


function requireAuth (to, from, next) {

    //console.log("REQUIRE AUTH", from, to)

    const access = localStorage.getItem('accessToken');
    const refresh = localStorage.getItem('refreshToken');

    if (access && refresh) {

      store.commit('storeAuthData', {
        access: access,
        refresh: refresh,
      })

      store.commit('userFromLocalToStore')

      let user_permissions = store.getters.getUserPermissions;

      let allowed = true;

      if (to.meta) {

        if (to.meta.permissions) {

          to.meta.permissions.forEach(item => {
            if (user_permissions.includes(item)) {
              allowed = true
            }
          });


          if (allowed) {
            /*var r = localStorage.getItem("redirect_to");
            var persist = false;
            if(r && r.slice(0,7) == "PERSIST"){
              persist = true;
            }
            if(to.path !== "/" && !persist){
              localStorage.setItem("redirect_to", to.path)
            }*/

            next();

          } else {

            console.log("DENIED", perm)

            let alert = {
              type: 'warning',
              text: 'You are not authorised to access that page.'
            };

            store.commit(
              'setGlobalAlert',
              alert
            );

            if (to.path !== '/dashboard') {
              next("/dashboard");
            }

          }

        }

      }

      next();

    } else {

      store.dispatch('setUnAuthorisedAlert');

      //console.log("NO TOKEN IN ROUTER", from, to)

      console.log("Desired path after login "+to.path)
      localStorage.setItem("redirect_to", to.path)

      if(from.name === null){
        next("/")
      }

    }

}


let system_routes = [
  {
    path: '/system-config',
    name: 'System Configuration',
    meta: {
      label: 'Configuration',
      permissions: ['full_admin',]
    },
    component: DefaultContainer,
    beforeEnter: requireAuth,
    children: [
      {
        path: '/system-config/letter-suite',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          label: 'Letters',
          permissions: ['full_admin',]
        },
        beforeEnter: requireAuth,
        children: [
          {
            path: '',
            name: 'Letters',
            component: Letters,
            meta: {
              label: 'Letters',
              permissions: ['full_admin',],
              permission_name: ["correspondence.views.backend.LetterSuiteAPIView.list.get"],
            },
          },
        ]
      },
      {
        path: '/system-config/documents',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          label: 'Documents',
          permissions: ['full_admin',]
        },
        beforeEnter: requireAuth,
        children: [
          {
            path: '',
            name: 'Documents',
            component: Documents,
            meta: {
              label: 'Documents',
              permissions: ['full_admin',],
              permission_name: ["core.views.document.DocumentAPIView.list.get"],
            },
          },
        ]
      },
      {
        path: '/system-config/claim-documents',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          label: 'Claim Documents',
          permissions: ['full_admin',]
        },
        beforeEnter: requireAuth,
        children: [
          {
            path: '',
            name: 'Claim Documents',
            component: ClaimDocuments,
            meta: {
              label: 'Claim Documents',
              permissions: ['full_admin',],
              permission_name: ["core.views.claim_document.ClaimDocumentAPIView.list.get"],
            },
          },
        ]
      },
      {
        path: '/system-config/exclusions',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        meta: {
          label: 'Exclusions',
          permissions: ['full_admin',],
        },
        beforeEnter: requireAuth,
        children: [
          {
            path: '',
            name: 'Exclusions',
            component: Exclusions,
            meta: {
              label: 'Claim Exclusion',
              permissions: ['full_admin',],
              permission_name: ["core.views.exclusion.ClaimExclusionAPIView.list.get"],
            },
          },
          {
            path: ':product',
            meta: {
              label: 'Exclusion',
              permissions: ['full_admin',],
              permission_name: [
                "core.views.exclusion.ClaimExclusionAPIView.retrieve.get",
              ],

            },
            name: 'Exclusion',
            beforeEnter: requireAuth,
            component: Exclusion,
          },
        ]
      },
      {
          path: '/system-config/products',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          meta: {
            label: 'Products',
            permissions: ['full_admin',]
          },
          beforeEnter: requireAuth,
          children: [
            {
              path: '',
              name: 'Products',
              component: Products,
              meta: {
                label: 'Select Product',
                permissions: ['full_admin',],
                permission_name: ["core.views.product.ProductViewSet.list.get"],
              },
            },
            {
              path: ':product',
              meta: {
                label: 'Product',
                permissions: ['full_admin',],
                permission_name: [
                  "core.views.product.ProductViewSet.retrieve.get",
                ],
              },
              name: 'Product',
              beforeEnter: requireAuth,
              component: Product,
            },
            /*{
              path: ':product/insurers',
              meta: {
                label: 'Insurers',
                permissions: ['full_admin',]
              },
              name: 'Insurers',
              beforeEnter: requireAuth,
              component: Insurers,
            },
            {
              path: ':product/insurers/:insurer/setting/:id/edit',
              meta: {
                label: 'Insurer Setting Edit',
                permissions: ['full_admin',]
              },
              name: 'InsurerSettingEdit',
              beforeEnter: requireAuth,
              component: InsurerSettingEdit,
            },
            {
              path: ':product/insurers/:insurer/setting/add',
              meta: {
                label: 'Insurer Setting Add',
                permissions: ['full_admin',]
              },
              name: 'InsurerSettingAdd',
              beforeEnter: requireAuth,
              component: InsurerSettingAdd,
            },
            {
              path: ':product/insurers/:insurer',
              meta: {
                label: 'Insurer',
                permissions: ['full_admin',]
              },
              name: 'Insurer',
              beforeEnter: requireAuth,
              component: Insurer,
            },
            {
              path: ':product/insurers/:insurer/edit',
              meta: {
                label: 'Insurer Edit',
                permissions: ['full_admin',]
              },
              name: 'InsurerEdit',
              beforeEnter: requireAuth,
              component: InsurerEdit,
            },
            {
              path: ':product/addons',
              meta: {
                label: 'Addons',
                permissions: ['full_admin',]
              },
              name: 'Addons',
              beforeEnter: requireAuth,
              component: Addons,
            },
            {
              path: ':product/addons/:id',
              meta: {
                label: 'Addon',
                permissions: ['full_admin',]
              },
              name: 'Addon',
              beforeEnter: requireAuth,
              component: Addon,
            },
            {
              path: ':product/addons/:id/edit',
              meta: {
                label: 'Addon Edit',
                permissions: ['full_admin',]
              },
              name: 'AddonEdit',
              beforeEnter: requireAuth,
              component: AddonEdit,
            },
            {
              path: ':product/addons/add',
              meta: {
                label: 'Addon Add',
                permissions: ['full_admin',]
              },
              name: 'AddonAdd',
              beforeEnter: requireAuth,
              component: AddonAdd,
            },
            {
              path: ':product/endorsements',
              meta: {
                label: 'Endorsements',
                permissions: ['full_admin',]
              },
              name: 'Endorsements',
              beforeEnter: requireAuth,
              component: Endorsements,
            },
            {
              path: ':product/endorsements/:id',
              meta: {
                label: 'Endorsement',
                permissions: ['full_admin',]
              },
              name: 'Endorsement',
              beforeEnter: requireAuth,
              component: Endorsement,
            },
            {
              path: ':product/endorsements/:id/edit',
              meta: {
                label: 'Endorsement Edit',
                permissions: ['full_admin',]
              },
              name: 'EndorsementEdit',
              beforeEnter: requireAuth,
              component: EndorsementEdit,
            },
            {
              path: ':product/eligibility-questions',
              meta: {
                label: 'Eligibility Questions',
                permissions: ['full_admin',]
              },
              name: 'EligibilityQuestions',
              beforeEnter: requireAuth,
              component: EligibilityQuestions,
            },
            {
              path: ':product/eligibility-questions/:id',
              meta: {
                label: 'Eligibility Question',
                permissions: ['full_admin',]
              },
              name: 'EligibilityQuestion',
              beforeEnter: requireAuth,
              component: EligibilityQuestion,
            },
            {
              path: ':product/eligibility-questions/:id/edit',
              meta: {
                label: 'Eligibility Question Edit',
                permissions: ['full_admin',]
              },
              name: 'EligibilityQuestionEdit',
              beforeEnter: requireAuth,
              component: EligibilityQuestionEdit,
            },
            {
              path: ':product/commission/:id',
              meta: {
                label: 'Commission',
                permissions: ['full_admin',]
              },
              name: 'CommissionView',
              beforeEnter: requireAuth,
              component: Commission,
            },
            {
              path: ':product/commission/:id/edit',
              meta: {
                label: 'Commission Edit',
                permissions: ['full_admin',]
              },
              name: 'CommissionEdit',
              beforeEnter: requireAuth,
              component: CommissionEdit,
            },
            {
              path: ':product/commission/add',
              meta: {
                label: 'Commission Add',
                permissions: ['full_admin',]
              },
              name: 'CommissionAdd',
              beforeEnter: requireAuth,
              component: CommissionAdd,
            },
            {
              path: ':product/commission',
              meta: {
                label: 'Commission',
                permissions: ['full_admin',]
              },
              name: 'Commission',
              beforeEnter: requireAuth,
              component: Commissions,
            },
            {
              path: ':product/commissionpanels',
              meta: {
                label: 'Panels',
                permissions: ['full_admin',]
              },
              name: 'Panels',
              beforeEnter: requireAuth,
              component: Panels,
            },
            {
              path: ':product/commissionpanels/:id',
              meta: {
                label: 'Panel',
                permissions: ['full_admin',]
              },
              name: 'Panel',
              beforeEnter: requireAuth,
              component: Panel,
            },
            {
              path: ':product/commissionpanels/:id/edit',
              meta: {
                label: 'Panel Edit',
                permissions: ['full_admin',]
              },
              name: 'PanelEdit',
              beforeEnter: requireAuth,
              component: PanelEdit,
            },
            {
              path: ':product/commissionpanels/add',
              meta: {
                label: 'Panel Add',
                permissions: ['full_admin',]
              },
              name: 'PanelAdd',
              beforeEnter: requireAuth,
              component: PanelAdd,
            },
            {
              path: ':product/rates/:insurer/table/:id',
              meta: {
                label: 'Tables Rates',
                permissions: ['full_admin',]
              },
              name: 'TableRates',
              beforeEnter: requireAuth,
              component: TableRates
            },
            {
              path: ':product/rates/:insurer/table/:id/rate/:rateid/edit',
              meta: {
                label: 'Table Rate Edit',
                permissions: ['full_admin',]
              },
              name: 'TableRateEdit',
              beforeEnter: requireAuth,
              component: RateEdit
            },
            {
              path: ':product/rates/:insurer/table/:id/rate/:rateid',
              meta: {
                label: 'Table Rate',
                permissions: ['full_admin',]
              },
              name: 'TableRate',
              beforeEnter: requireAuth,
              component: Rate
            },
            {
              path: ':product/rates/:insurer',
              meta: {
                label: 'Tables',
                permissions: ['full_admin',]
              },
              name: 'Tables',
              beforeEnter: requireAuth,
              component: Tables
            },
            {
              path: ':product/rates',
              meta: {
                label: 'Rates - Insurers',
                permissions: ['full_admin',]
              },
              name: 'RatesInsurers',
              beforeEnter: requireAuth,
              component: RatesInsurers,
            },*/
          ]
        },

        {
          path: '/system-config/task',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          meta: {
            label: 'Task Due Dates',
            permissions: ['full_admin',]
          },
          beforeEnter: requireAuth,
          children: [
            {
              path: '',
              name: 'TasksConfig',
              component: TasksConfig,
              meta: {
                label: 'Due Dates',
                permissions: ['full_admin',],
                permission_name: ["claim.views.backend.task.TaskDueDateViewSet.list.get"],
              },
            },
            {
              path: ':id',
              meta: {
                label: 'Task Due Date Edit',
                permissions: ['full_admin',],
                permission_name: [
                  "claim.views.backend.task.TaskDueDateViewSet.retrieve.get",
                ],
  
              },
              name: 'TaskConfigEdit',
              beforeEnter: requireAuth,
              component: TaskConfigEdit,
            },
          ]
        },
        {
          path: '/system-config/questionsections',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          meta: {
            label: 'Question Sections',
            permissions: ['full_admin',]
          },
          beforeEnter: requireAuth,
          children: [
            {
              path: '',
              name: 'QuestionSections',
              component: QuestionSections,
              meta: {
                label: 'List',
                permissions: ['full_admin',],
                permission_name: [""],
              },
            },
            {
              path: ':id',
              meta: {
                label: 'View',
                permissions: ['full_admin',],
                permission_name: [
                  "",
                ],
  
              },
              name: 'QuestionSection',
              beforeEnter: requireAuth,
              component: QuestionSection,
            },
          ]
        },
        {
          path: '/system-config/portal/pages',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          meta: {
            label: 'Portal Pages',
            permissions: ['full_admin',]
          },
          beforeEnter: requireAuth,
          children: [
            {
              path: '',
              name: 'PortalPages',
              component: PortalPages,
              meta: {
                label: 'List',
                permissions: ['full_admin',],
                permission_name: [""],
              },
            },
            {
              path: ':id',
              meta: {
                label: 'View',
                permissions: ['full_admin',],
                permission_name: [
                  "",
                ],
  
              },
              name: 'PortalPage',
              beforeEnter: requireAuth,
              component: PortalPage,
            },
          ]
        },
        {
          path: '/system-config/portal/popups',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          meta: {
            label: 'Portal Popups',
            permissions: ['full_admin',]
          },
          beforeEnter: requireAuth,
          children: [
            {
              path: '',
              name: 'PortalPopups',
              component: PortalPopups,
              meta: {
                label: 'List',
                permissions: ['full_admin',],
                permission_name: [""],
              },
            },
            {
              path: ':id',
              meta: {
                label: 'View',
                permissions: ['full_admin',],
                permission_name: [
                  "",
                ],
  
              },
              name: 'PortalPopup',
              beforeEnter: requireAuth,
              component: PortalPopup,
            },
          ]
        },
        {
          path: '/system-config/portal/styles',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          meta: {
            label: 'Portal Styling',
            permissions: ['full_admin',]
          },
          beforeEnter: requireAuth,
          children: [
            {
              path: '',
              name: 'PortalStyles',
              component: PortalStyles,
              meta: {
                label: 'List',
                permissions: ['full_admin',],
                permission_name: [""],
              },
            },
            {
              path: ':id',
              meta: {
                label: 'View',
                permissions: ['full_admin',],
                permission_name: [
                  "",
                ],
  
              },
              name: 'PortalStyle',
              beforeEnter: requireAuth,
              component: PortalStyle,
            },
          ]
        },
        /*{
          path: 'users',
          component: {
            render (c) { return c('router-view') }
          },
          meta: {
            label: 'Users',
            permissions: ['full_admin', 'data_management',]
          },
          beforeEnter: requireAuth,
          children: [
            {
              path: '',
              name: 'SCUsers',
              component: SCUsers,
              meta: {
                label: 'List',
                permissions: ['full_admin', 'data_management',]
              },
            },
            {
              path: ':id',
              meta: {
                label: 'Details',
                permissions: ['full_admin', 'data_management',]
              },
              name: 'SCUser',
              beforeEnter: requireAuth,
              component: SCUser,
            },
            {
              path: 'create',
              meta: {
                label: 'Create',
                permissions: ['full_admin',]
              },
              name: 'SCUserCreate',
              beforeEnter: requireAuth,
              component: SCUserCreate,
            },
            {
              path: ':id/edit',
              meta: {
                label: 'Edit',
                permissions: ['full_admin',]
              },
              name: 'SCUserEdit',
              beforeEnter: requireAuth,
              component: SCUserEdit,
            },
            {
              path: ':id/password',
              meta: {
                label: 'Set Password',
                permissions: ['full_admin',]
              },
              name: 'SCUserSetPassword',
              beforeEnter: requireAuth,
              component: SCSetPassword,
            },
          ]
        },
      {
          path: 'email-settings',
          redirect: 'email-settings',
          name: 'SCEmailSettings',
          component: {
            render (c) { return c('router-view') }
          },
          meta: {
            label: 'Email Settings',
            permissions: ['full_admin',]
          },
          beforeEnter: requireAuth,
          children: [
            {
              path: '',
              component: SCEmailSettings,
              meta: {
                label: 'List',
                permissions: ['full_admin',]
              },
            },
            {
              path: ':id',
              meta: {
                label: 'Details',
                permissions: ['full_admin',]
              },
              name: 'SCEmailSetting',
              beforeEnter: requireAuth,
              component: SCEmailSetting,
            },
            {
              path: 'create',
              meta: {
                label: 'Create',
                permissions: ['full_admin',]
              },
              name: 'SCEmailSettingCreate',
              beforeEnter: requireAuth,
              component: SCEmailSettingCreate,
            },
            {
              path: ':id/edit',
              meta: {
                label: 'Edit',
                permissions: ['full_admin',]
              },
              name: 'SCEmailSettingEdit',
              beforeEnter: requireAuth,
              component: SCEmailSettingEdit,
            },
          ]
        },*/

    ]
  }
]

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      name: 'Login',
      component: Login,
    },
    {
      path: '',
      redirect: '/dashboard',
      name: 'Home',
      meta: {
        permissions: ['full_admin', 'data_management', 'customer_services',],
      },
      component: DefaultContainer,
      beforeEnter: requireAuth,
      children: [
        {
          path: '/change-password',
          name: 'ChangePassword',
          beforeEnter: requireAuth,
          component: ChangePassword,
          meta: {
            label: 'Change Password',
            permission_name: ["users.views.backend.UserViewSet.change_password.put"],
          }
        },
        {
          path: '/dashboard',
          name: 'Dashboard',
          meta: {
            permissions: ['full_admin', 'data_management', 'customer_services',],
            permission_name: ["backoffice.dashboard.view"],
          },
          component: Dashboard,
          beforeEnter: requireAuth,
        },
        /*{
          path: 'gdpr-audits',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          meta: {
            label: 'GDPR Audits',
            permissions: ['full_admin', 'data_management',]
          },
          beforeEnter: requireAuth,
          children: [
            {
              path: '',
              name: 'GDPRAudits',
              component: GDPRAudits,
              meta: {
                label: 'GDPR Audits',
                permissions: ['full_admin', 'data_management',]
              },
            },
            {
              path: ':id',
              meta: {
                label: 'GDPR Audit',
                permissions: ['full_admin', 'data_management',]
              },
              name: 'GDPRAudit',
              beforeEnter: requireAuth,
              component: GDPRAudit,
            },
          ]
        },*/
        {
          path: 'payments',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          meta: {
            label: 'Payments',
            permissions: ['full_admin', 'data_management',]
          },
          beforeEnter: requireAuth,
          children: [
            {
              path: '',
              name: 'Payments',
              component: Payments,
              meta: {
                label: 'Payments',
                permissions: ['full_admin', 'data_management',],
                permission_name: ["payment.views.PaymentViewSet.list.get"],
              },
            },
            {
              path: ':id',
              meta: {
                label: 'Payment',
                permissions: ['full_admin', 'data_management',],
                permission_name: ["payment.views.PaymentViewSet.retrieve.get"],
              },
              name: 'Payment',
              beforeEnter: requireAuth,
              component: Payment,
            },{
              path: ':id/edit',
              meta: {
                label: 'Payment Edit',
                permissions: ['full_admin', 'data_management',],
                permission_name: ["payment.views.PaymentViewSet.partial_update.patch"],
              },
              name: 'PaymentEdit',
              beforeEnter: requireAuth,
              component: PaymentEdit,
            },
          ]
        },
        /*{
          path: 'workflows',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          meta: {
            label: 'Workflows',
            permissions: ['full_admin', 'data_management',]
          },
          beforeEnter: requireAuth,
          children: [
            {
              path: '',
              name: 'Workflows',
              component: Workflows,
              meta: {
                label: 'Workflows',
                permissions: ['full_admin', 'data_management',]
              },
            },
            {
              path: ':id',
              meta: {
                label: 'Workflow',
                permissions: ['full_admin', 'data_management',]
              },
              name: 'Workflow',
              beforeEnter: requireAuth,
              component: Workflow,
            },
            {
              path: ':id/edit',
              meta: {
                label: 'Workflow Edit',
                permissions: ['full_admin', 'data_management',]
              },
              name: 'WorkflowEdit',
              beforeEnter: requireAuth,
              component: WorkflowEdit,
            },
          ]
        },*/
        {
          path: 'tasks',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          meta: {
            label: 'Tasks',
            permissions: ['full_admin', 'data_management',]
          },
          beforeEnter: requireAuth,
          children: [
            {
              path: '',
              name: 'Tasks',
              component: Tasks,
              meta: {
                label: 'Tasks',
                permissions: ['full_admin', 'data_management',],
                permission_name: ["claim.views.backend.task.TaskViewSet.list.get"],
              },
            },
            {
              path: 'my/tasks',
              name: 'MyTasks',
              component: MyTasks,
              meta: {
                label: 'My Tasks',
                permissions: ['full_admin', 'data_management',],
                permission_name: ["claim.views.backend.task.TastViewSet.mytasks.get"],
              },
            },
            {
              path: ':id',
              meta: {
                label: 'Task',
                permissions: ['full_admin', 'data_management',],
                permission_name: ["claim.views.backend.task.TastViewSet.retrieve.get"],
              },
              name: 'Task',
              beforeEnter: requireAuth,
              component: Task,
            },
            {
              path: ':id/edit',
              meta: {
                label: 'Task Edit',
                permissions: ['full_admin', 'data_management',],
                permission_name: ["claim.views.backend.task.TastViewSet.update.put"],
              },
              name: 'TaskEdit',
              beforeEnter: requireAuth,
              component: TaskEdit,
            },
          ]
        },
        {
          path: 'users',
          component: {
            render (c) { return c('router-view') }
          },
          meta: {
            label: 'Users',
            permissions: ['full_admin', 'data_management',]
          },
          beforeEnter: requireAuth,
          children: [
            {
              path: '',
              name: 'Users',
              component: Users,
              meta: {
                label: 'Users',
                permissions: ['full_admin', 'data_management',],
                permission_name: ["user.views.backend.users.UserViewSet.list.get"],
              },
            },
            {
              path: 'create',
              meta: {
                label: 'Create',
                permissions: ['full_admin',],
                permission_name: ["user.views.backend.users.UserViewSet.create.post"],
              },
              name: 'UserCreate',
              beforeEnter: requireAuth,
              component: UserCreate,
            },
            {
              path: ':id',
              meta: {
                label: 'User Details',
                permissions: ['full_admin', 'data_management',],
                permission_name: ["user.views.backend.users.UserViewSet.retrieve.get"],
              },
              name: 'User',
              beforeEnter: requireAuth,
              component: User,
            },
            {
              path: 'edit/:id',
              meta: {
                label: 'Edit',
                permissions: ['full_admin',],
                permission_name: ["user.views.backend.users.UserViewSet.update.put"],
              },
              name: 'UserEdit',
              beforeEnter: requireAuth,
              component: UserEdit,
            },
            {
              path: 'edit/:id',
              meta: {
                label: 'Set Password',
                permissions: ['full_admin',],
                permission_name: ["user.views.backend.users.UserViewSet.set_password.put"],
              },
              name: 'UserSetPassword',
              beforeEnter: requireAuth,
              component: SetPassword,
            },
          ]
        },
        {
          path: 'vets',
          component: {
            render (c) { return c('router-view') }
          },
          meta: {
            label: 'Vets',
            permissions: ['full_admin', 'data_management',]
          },
          beforeEnter: requireAuth,
          children: [
            {
              path: '',
              name: 'Vets',
              component: Vets,
              meta: {
                label: 'Vets',
                permissions: ['full_admin', 'data_management',],
                permission_name: ["user.views.backend.vet.VetViewSet.list.get"],
              },
            },
            {
              path: ':id',
              meta: {
                label: 'Vet Details',
                permissions: ['full_admin', 'data_management',],
                permission_name: ["user.views.backend.vet.VetViewSet.retrieve.get"],
              },
              name: 'Vet',
              beforeEnter: requireAuth,
              component: Vet,
            },
            {
              path: ':id/onboarding',
              meta: {
                label: 'Vet Onboardings',
                permissions: ['full_admin',],
                permission_name: ["user.views.backend.vet.VetViewSet.onboarding.list"],
              },
              name: 'VetOnboardings',
              beforeEnter: requireAuth,
              component: VetOnboardings,
            },
            {
              path: ':id/onboarding/:onboarding_id',
              meta: {
                label: 'Vet Onboarding',
                permissions: ['full_admin',],
                permission_name: ["user.views.backend.vet.VetViewSet.onboarding.get"],
              },
              name: 'VetOnboarding',
              beforeEnter: requireAuth,
              component: VetOnboarding,
            }
            /*{
              path: 'edit/:id',
              meta: {
                label: 'Set Password',
                permissions: ['full_admin',],
              },
              name: 'VetSetPassword',
              beforeEnter: requireAuth,
              component: SetPassword,
            },*/
          ]
        },
        {
          path: 'customers',
          component: {
            render (c) { return c('router-view') }
          },
          meta: {
            label: 'Customers',
            permissions: ['full_admin', 'data_management',]
          },
          beforeEnter: requireAuth,
          children: [
            {
              path: '',
              name: 'Customers',
              component: Customers,
              meta: {
                label: 'Customers',
                permissions: ['full_admin', 'data_management',],
                permission_name: ["user.views.backend.customer.CustomerViewSet.list.get"],
              },
            },
            {
              path: ':id',
              meta: {
                label: 'Customer Details',
                permissions: ['full_admin', 'data_management',],
                permission_name: ["user.views.backend.customer.CustomerViewSet.retrieve.get"],
              },
              name: 'Customer',
              beforeEnter: requireAuth,
              component: Customer,
            },
            /*{
              path: 'edit/:id',
              meta: {
                label: 'Set Password',
                permissions: ['full_admin',]
              },
              name: 'CustomerSetPassword',
              beforeEnter: requireAuth,
              component: SetPassword,
            },*/
          ]
        },
        {
          path: 'customer-service',
          redirect: '/customer-service',
          name: 'Customer Service',
          meta: {
            permissions: ['full_admin', 'data_management', 'customer_services',],
            permission_name: ["backoffice.customer_service"],
          },
          component: {
            render (c) { return c('router-view') }
          },
          beforeEnter: requireAuth,
          children: [
            {
              path: '',
              name: '',
               meta: {
                permissions: ['full_admin', 'data_management', 'customer_services',]
              },
              component: CustomerService,
              beforeEnter: requireAuth,
            },
            /*{
              path: 'quotes',
              name: 'Quotes',
              component: Quotes,
               meta: {
                permissions: ['full_admin', 'data_management', 'customer_services',]
              },
              beforeEnter: requireAuth,
            },
            {
              path: 'quotes/:id',
              name: 'Quote',
              meta: {
                permissions: ['full_admin', 'data_management', 'customer_services',]
              },
              component: Quote,
              beforeEnter: requireAuth,
            },*/
            {
              path: 'policies',
              name: 'Policies',
              component: Policies,
              meta: {
                permissions: ['full_admin', 'data_management', 'customer_services',],
                permission_name: ["policy.views.backend.policy.PolicyViewSet.list.get"],
              },
              beforeEnter: requireAuth,
            },
            {
              path: 'policies/:id',
              name: 'Policy',
              component: Policy,
              meta: {
                permissions: ['full_admin', 'data_management', 'customer_services',],
                permission_name: ["policy.views.backend.policy.PolicyViewSet.retrieve.get"],
              },
              beforeEnter: requireAuth,
            },
            {
              path: 'claims',
              name: 'Claims',
              component: Claims,
              meta: {
                permissions: ['full_admin', 'data_management', 'customer_services',],
                permission_name: ["claim.views.backend.claim.ClaimViewSet.list.get"],
              },
              beforeEnter: requireAuth,
            },
            {
              path: 'claims/:id',
              name: 'Claim',
              component: Claim,
              meta: {
                permissions: ['full_admin', 'data_management', 'customer_services',],
                permission_name: ["claim.views.backend.claim.ClaimViewSet.retrieve.get"],
              },
              beforeEnter: requireAuth,
            },
            {
              path: 'complaints',
              name: 'Complaints',
              component: Complaints,
              meta: {
                permissions: ['full_admin', 'data_management', 'customer_services',],
                permission_name: ["complaint.views.backend.complaint.ComplaintBackendViewSet.list.get"],
              },
              beforeEnter: requireAuth,
            },
            {
              path: 'complaints/:id',
              name: 'Complaint',
              component: Complaint,
              meta: {
                permissions: ['full_admin', 'data_management', 'customer_services',],
                permission_name: ["complaint.views.backend.complaint.ComplaintBackendViewSet.retrieve.get"],
              },
              beforeEnter: requireAuth,
            },
            {
              path: 'complaints/:id/edit',
              name: 'ComplaintEdit',
              component: ComplaintEdit,
              meta: {
                permissions: ['full_admin', 'data_management', 'customer_services',],
                permission_name: ["complaint.views.backend.complaint.ComplaintBackendViewSet.update.put"],
              },
              beforeEnter: requireAuth,
            },
            {
              path: 'search/customer',
              name: 'Search Customer',
              component: SearchCustomer,
              meta: {
                permissions: ['full_admin', 'data_management', 'customer_services',],
                permission_name: ["user.views.backend.customer.CustomerViewSet.search.get"],
              },
              beforeEnter: requireAuth,
            },
            {
              path: 'search/vet',
              name: 'Search Vet',
              component: SearchVet,
              meta: {
                permissions: ['full_admin', 'data_management', 'customer_services',],
                permission_name: ["user.views.backend.vet.VetViewSet.search.get"],
              },
              beforeEnter: requireAuth,
            },
            {
              path: 'search/claim',
              name: 'Search Claim',
              component: SearchClaim,
              meta: {
                permissions: ['full_admin', 'data_management', 'customer_services',],
                permission_name: ["claim.views.backend.claim.ClaimViewSet.search.get"],
              },
              props: true,
              beforeEnter: requireAuth,
            },
            {
              path: 'search/policy',
              name: 'Search Policy',
              component: SearchPolicy,
              meta: {
                permissions: ['full_admin', 'data_management', 'customer_services',],
                permission_name: ["policy.views.backend.policy.PolicyViewSet.search.get"],
              },
              props: true,
              beforeEnter: requireAuth,
            },
            {
              path: 'search/complaint',
              name: 'Search Complaint',
              component: SearchComplaint,
              meta: {
                permissions: ['full_admin', 'data_management', 'customer_services',],
                permission_name: ["complaint.views.backend.complaint.ComplaintBackendViewSet.search.get"],
              },
              beforeEnter: requireAuth,
            },
            {
              path: 'search',
              name: 'Search',
              component: Search,
              meta: {
                permissions: ['full_admin', 'data_management', 'customer_services',],
                permission_name: [
                  "user.views.backend.customer.CustomerViewSet.search.get",
                  "user.views.backend.vet.VetViewSet.search.get",
                  "claim.views.backend.claim.ClaimViewSet.search.get",
                  "policy.views.backend.policy.PolicyViewSet.search.get",
                  "complaint.views.backend.complaint.ComplaintBackendViewSet.search.get",
                ],
              },
              beforeEnter: requireAuth,
            },              
            /*{
              path: 'gdpr-search',
              name: 'GDPR Search',
              component: GDPRCleanse,
              meta: {
                permissions: ['full_admin', 'customer_services',]
              },
              beforeEnter: requireAuth,
            },*/
            /*{
              path: 'mta/:id',
              name: 'MTA',
              meta: {
                permissions: ['full_admin', 'customer_services',]
              },
              get component() {
                  let product = localStorage.getItem('selected_product');
                  if (parseInt(product) === 1) {
                    return MTANS
                  } else {
                    return MTAHH
                  }
              },
              beforeEnter: requireAuth,
            }*/
          ]
        },
        {
          path: 'reporting',
          redirect: '/reporting/export',
          name: 'Reporting',
          meta: {
            permissions: ['full_admin', 'data_management', 'customer_services',],
          },
          component: {
            render (c) { return c('router-view') }
          },
          beforeEnter: requireAuth,
          children: [
            {
              path: 'import',
              name: 'Import',
              component: Import,
              meta: {
                permissions: ['full_admin', 'data_management', 'customer_services',],
                permission_name: [
                  "reporting.views.ReportingViewSet.import_file.get",
                  "reporting.views.ReportingViewSet.import_file.create"
                ],
              },
              beforeEnter: requireAuth,
            },  
            {
              path: 'export',
              name: 'Export',
              component: Export,
              meta: {
                permissions: ['full_admin', 'data_management', 'customer_services',],
                permission_name: ["reporting.views.ReportingViewSet.standard.get"],
              },
              beforeEnter: requireAuth,
            },  

          ]
        }
      ]
    },
    {
      path: '*',
      name: '404',
      component: Page404
    },
    ...system_routes
  ]
})
