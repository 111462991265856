import Vue from 'vue'
import Vuex from 'vuex'

// State
import auth_state from './state/auth'
import alert_state from './state/alert'
import callcentre_state from './state/callcentre'

// Mutations
import auth_mutations from './mutations/auth'
import alert_mutations from './mutations/alert'
import callcentre_mutations from './mutations/callcentre'

// Actions
import auth_actions from './actions/auth'
import quote_actions from './actions/quote'
import alert_actions from './actions/alert'

// Getters
import auth_getters from './getters/auth'
import quote_getters from './getters/quote'
import alert_getters from './getters/alert'
import callcentre_getters from './getters/callcentre'

Vue.use(Vuex);

const state = Object.assign({},
  auth_state,
  alert_state,
  callcentre_state,
);

const mutations = Object.assign({},
  auth_mutations,
  alert_mutations,
  callcentre_mutations,
);

const actions = Object.assign({},
  auth_actions,
  quote_actions,
  alert_actions
);

const getters = Object.assign({},
  auth_getters,
  quote_getters,
  alert_getters,
  callcentre_getters,
);


export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters
})
