<template>
  <div class="input">
    <label :for="name">{{ label }}</label>
    <b-input class="mb-2 mr-sm-2 mb-sm-0"
      v-bind="$attrs"
      :state="!hasError"
      :aria-describedby="`${name}-error`"
      :value="value"
      :name="name"
      :id="name"
      :v-on="{
        ...$listeners,
        input: event => $emit('input', event.target.value)
      }"
    ></b-input>
    <b-form-invalid-feedback :id="`${name}-error`" class="error" v-if="hasError && error">{{ error }}</b-form-invalid-feedback>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number]
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    error: {
      type: String,
      required: false
    },
    hasError: {
      type: Boolean,
      default: false
    }
  }
}
</script>
