<template>
  <div id="app">
    <router-view :key="$route.fullPath" ></router-view>

    <div v-if="loading" class="modal modal-custom fade bd-example-modal-lg show d-block" data-backdrop="static" data-keyboard="false" tabindex="-1">
      <div class="modal-dialog modal-sm" >
          <div class="modal-content">
              <i class="fas fa-spinner text-white fa-spin fa-3x"></i>
          </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'app',
  computed: {
    loading() {
      return this.$store.state.loading
    },
    user_permissions() {
      return this.$store.getters.getUserPermissions
    }
  },
  created() {
    console.log("APP CREATED")
    var codePushKey = "UPDATE-8o6b9c02n0c20";
    var auto_code_push = localStorage.getItem(codePushKey);
    // the codepush has ran before
    if(auto_code_push){
      if(auto_code_push == codePushKey){
        // show popup here if necessary
        alert("Welcome, to the world of tomorrow!\n\n(New Code)");
        localStorage.setItem(codePushKey, true);
      }
    } else {
      this.$store.dispatch('codePushRefresh', codePushKey);
    }
  },
  /*watch: {
    '$route'(to) {
      if (to.currentRoute.meta.reload === true) {
        //window.location.reload()
      }
    }
  }*/
}
</script>

<style lang="scss">
  // CoreUI Icons Set
  @import '~@coreui/icons/css/coreui-icons.min.css';
  /* Import Font Awesome Icons Set */
  $fa-font-path: '~font-awesome/fonts/';
  @import '~font-awesome/scss/font-awesome.scss';
  /* Import Simple Line Icons Set */
  $simple-line-font-path: '~simple-line-icons/fonts/';
  @import '~simple-line-icons/scss/simple-line-icons.scss';
  /* Import Flag Icons Set */
  @import '~flag-icon-css/css/flag-icon.min.css';
  /* Import Bootstrap Vue Styles */
  @import '~bootstrap-vue/dist/bootstrap-vue.css';
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>