
export default {
    isAuthenticated(state) {
        return state.accessToken != null
    },
    getAlert (state) {
      return state.alert
    },
    isLoading (state) {
      return state.loading
    },
    getUnAuthorisedAlert (state) {
      return state.unAuthorisedAlert
    },
    getUserPermissions (state) {
      if (state.user_permissions) {
        return state.user_permissions
      } else {
        if (localStorage.getItem('user_permissions')) {
          return localStorage.getItem('user_permissions')
        }
      }
      return []
    },
    getAgentCallStatus (state) {
      return state.acceptingCalls
    },
    getPermissions(state){
      return state.permissions;
    },
    getTeams(state){
      return state.teams;
    },
    hasPermission: (state) => (data) => {
      console.log("HAS PERM", data)
      if (localStorage.getItem('permissions')) {
        return JSON.parse(localStorage.getItem('permissions')).includes(data);
      }
      return false;
    },
    inTeam(state, data){
      return state.teams.includes(data);
    }
}

