
export default {
    getCallcentreItem: (state) => (item_name) => {
      if(item_name in state.callcentreData){
        return state.callcentreData[item_name];
      }
      return null;
    },
}

