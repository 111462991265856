
export default {
    setCallcentreItem: (state, obj) => {
      var key = obj.name;
      var value = obj.value;
      if(key in state.callcentreData){
        state.callcentreData[key] = value;
        return true;
      }
      return null;
    },
}