
export default {
    setGlobalAlert ({commit}, data) {
      commit('setGlobalAlert', data)
    },
    unsetGlobalAlert ({commit}) {
        commit('unsetGlobalAlert')
    },
    setDashCancel ({commit}, data) {
      commit('setDashCancel', data)
    }
}
