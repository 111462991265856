
export default {
    setGlobalAlert(state, data) {
      state.global_alert_type = data.type;
      state.global_alert_text = data.text;
      state.global_alert = true;
      if(data.hasOwnProperty('timer')){
        state.global_alert_timer = data.timer
      } else {
        state.global_alert_timer = 5
      }
      state.global_alert_timeout = state.global_alert_timer
    },
    unsetGlobalAlert(state) {
      state.global_alert_type = null;
      state.global_alert_text = null;
      state.global_alert = false;
      state.global_alert_timeout = 0;
      state.global_alert_timer = 5;
    },
    setDashCancel(state, data){
      state.dash_cancel = data;
    }
}

