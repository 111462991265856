import { thistle } from 'color-name';

const Device = require('twilio-client').Device;

export default class Callcentre {
    constructor(options){
        this._debug = options["debug"];
        this._wsToken = options["ws_token"];
        this._wsURL = options["ws_url"];
        this._callToken = false;
        this._device = undefined;
        this._connection = null;
        this._socket = undefined;
        this._agentState = "0";
        this._callState = "0";
        this._isMuted = false;
        this._isOnHold = false;
        this._acceptingCalls = false;
        this._agentCallSid = null;
        this._customerCallSid = null;
        this._queueData = {};
        this._ringingQueueName = null;
        this._shutdown = false;
        this._possibleAgentStates = {
            "0": "Disconnected",
            "1": "Connected"
        }
        this._possibleCallStates = {
            "0": "Unavailable",
            "1": "Idle",
            "2": "Ringing (Incoming)",
            "3": "Ringing (Outgoing)",
            "4": "Caller On Hold",
            "5": "Call Active",
            "6": "Caller In Queue"
        }
        this.activeCall = false;
        this.activeRinging = false;
    }
    shutdown(){
        this._shutdown = true;
        if(this._device){
            this._device.destroy();
            this._device = undefined;
        }
        if(this._socket){
            this._socket.close();
            this._socket = undefined;
        }
    }
    setupEventHandlers(){
        this._device.on('ready', device => {
            this.setAgentState("1");
            this.setCallState("1");
            console.log("Twilio.Device is now ready for connections");
        });
        this._device.on('offline', device => {
            this.setAgentState("0");
            this.setCallState("0");
            console.log("Twilio.Device has dropped the connection");
        });
        this._device.on('error', error => {
            console.log("Twilio.Device Error: ", error.message);
        });
        this._device.on('incoming', connection => {
            this._connection = connection;
            this._connection.on('reject', connection => {
                this._connection = null;
                this._customerCallSid = null;
                this._agentCallSid = null;
                this.setCallState("1");
            });
            this.setCallState("2");
        });
        this._device.on('cancel', connection => {
            this._connection = null;
            this._customerCallSid = null;
            this._agentCallSid = null;
            this.setCallState("1");
        });
        this._device.on('connect', connection => {
            this._connection = connection;
            console.log("GET SIDS", connection)
            this.activeCall = true;
            this.getCallSids();
            this.setCallState("5");
        });
        this._device.on('disconnect', connection => {
            this._connection = null;
            this._customerCallSid = null;
            this._agentCallSid = null;
            this.activeCall = false;
            this.setCallState("1");
        });
    }
    setupWSEventHandlers(){
        this._socket.onopen = () => {
            console.log("Backoffice.WS is connected");
        }
        this._socket.onclose = (e) => {
            if(this._device){
                this._device.destroy();
            }
            this._device = undefined;
            this._socket = undefined;
            this.vueEmit("acceptingCallsCallback", false);
            this.setAgentState("0");
            this.setCallState("0");
            console.log("Backoffice.WS has disconnected: " + e.reason);
            if(!this._shutdown){
                console.log("Backoffice.WS reconnecting in 1 second");
                setTimeout( () => {
                    this.connectWS();
                }, 1000)
            }
        }
        this._socket.onerror = (e) => {
            console.log("Backoffice.WS error: " + e.message);
        }
        this._socket.onmessage = (e) => {
            var data = JSON.parse(e.data);
            console.log("Backoffice.WS incoming message", data);
            this.handleIncomingWSMessage(data.message);
        }
    }
    connectWS(reconnect=false){
        if(this._socket){
            this._socket.onclose = (e) => {
                console.log('closing old socket');
            }
            this._socket.close();
            this._socket = undefined;
            this._socket = new WebSocket(this._wsURL + this._wsToken + '&reconnect=true');
        } else {
            this._socket = new WebSocket(this._wsURL + this._wsToken);
        }
        
        this.setupWSEventHandlers();
    }
    handleIncomingWSMessage(message){
        console.log(message)
        if(message.event == 1){
            this._callToken = message.data;
        } else if(message.event == 2){
            this.vueEmit("acceptingCallsCallback", message.data);
            this._subscribedRooms = message.rooms;
            console.log(this._subscribedRooms)
            this.vueEmit("subscribedRoomsCallback", this._subscribedRooms);
            if(message.data && this._device === null){
                this.setupTwilioDevice();
            }
        } else if(message.event == 3){
            if(message.data.status){
                this.setCallState("4");
            } else {
                this.setCallState("5");
            }
        } else if(message.event == 4){
            this._customerCallSid = message.data;
        } else if(message.event == 5){
            // caller in queue
            this._queueData[message.data.queue_name] = message.data;
            if(!this.activeCall){
                if(message.data.members > 0 && !this.activeRinging){
                    this._ringingQueueName = message.data.queue_name;
                    this.setCallState("6");
                    this.activeRinging = true;
                    this.vueEmit("queueNameCallback", this._ringingQueueName);
                } else if(message.data.members == 0){
                    this._ringingQueueName = null;
                    this.setCallState("1");
                    this.activeRinging = false;
                    this.vueEmit("queueNameCallback", null);
                }
            }
        } else if(message.event == 8){
            this.vueEmit("callerIDCallback", message.data);
        }
    }
    sendWSMessage(event, data){
        console.log("Outgoing WS Message", event, data)
        if(this._socket.readyState == 1){
            this._socket.send(JSON.stringify({"message": {"event": event, "data": data}}));
        } else {
            setTimeout( () => {
                this.sendWSMessage(event, data);
            }, 1000)
        }
        
    }
    setupTwilioDevice(){
        this._device = new Device();
        this._device.setup(this._callToken, {
            debug: this._debug,
            closeProtection: true
        });
        this.setupEventHandlers();
    }
    setAcceptingCalls(status){
        if(!this._callToken) return;
        this._acceptingCalls = status;
        this.sendWSMessage(2, status);
        if(status){
            this.setupTwilioDevice();
        } else {
            if(this._device){
                this._device.destroy();
            }
            this._device = undefined;
        }
    }
    getCallSids(){
        var direction = this._connection._direction;
        if(direction == "INCOMING"){
            this._customerCallSid = this._connection.customParameters.get("origin_call_sid");
            this._agentCallSid = this._connection.parameters.CallSid;
        } else {
            this._agentCallSid = this._connection.parameters.CallSid;
        }
    }
    setHold(status){
        this.sendWSMessage(3, {"status": status, "sid": this._customerCallSid});
    }
    setAgentState(state){
        this._agentState = state;
        this.vueEmit("agentStateCallback", this._possibleAgentStates[state]);
    }
    setCallState(state){
        this._callState = state;
        this.vueEmit("callStateCallback", this._possibleCallStates[state]);
        if(state == "0" || state == "1"){
            this.vueEmit("callerIDCallback", null);
        } else if(state == "2" || state == "3" || state == "4" || state == "5"){
            if(this._connection && state == "5"){
                //this.sendWSMessage(8, this._connection.parameters.CallSid);
            }
        }
    }
    vueEmit(event, data){
        var event = new CustomEvent("vueEmit", {"detail": {"event": event, "data": data}});
        window.dispatchEvent(event);
    }
    connect(){
        this._shutdown = false;
        this.connectWS();
        this.setAgentState("0");
        this.setCallState("0");
    }
    acceptingCalls(status){
        this.setAcceptingCalls(status);
    }
    updateTwilioToken(token){
        this._callToken = token;
        this._device.setup(this._callToken);
    }
    dequeue(){
        this._connection = this._device.connect({
            queue: this._ringingQueueName,
        });
        this._connection.on('reject', connection => {
            this._connection = null;
            this.setCallState("1");
            this.activeRinging = false;
        });
        this.setCallState("2");
        this._connection.on('accept', connection => {
            this._connection = connection;
            this.setCallState("5");
            this.activeRinging = false;
            console.log(connection)
            //this.connectWS(true);
            setTimeout( () => {
                this.sendWSMessage(8, this._connection.parameters.CallSid);
            }, 2000);
            //this.sendWSMessage(8, this._connection.parameters.CallSid);
            /*var data = {
                "queue_name": this._ringingQueueName,
                "call_sid": connection.parameters.CallSid,
            }*/
            this._ringingQueueName = null;
            //this.sendWSMessage(6, data);
        });

        /*this._connection.on('disconnect', connection => {
            this.connectWS();
        });*/
    }
    dial(number){
        this._connection = this._device.connect({
            screenpop_number: number,
        });
        this._connection.on('reject', connection => {
            this._connection = null;
            this.setCallState("1");
        });
        this.setCallState("3");
    }
    answer(){
        if(!this._connection) return;
        this._connection.accept();
    }
    hangup(){
        if(!this._connection) return;
        if(this._connection.disconnect() == undefined){
            this._connection.reject();
        }
    }
    mute(status){
        if(!this._connection) return;
        this._isMuted = status;
        this._connection.mute(this._isMuted);
    }
    dtmf(string){
        if(!this._connection) return;
        this._connection.sendDigits(string);
    }
    correspondence(customer_id, type, object_id){
        var data = {
            "customer": customer_id,
            "type": type,
            "object_id": object_id,
        }
        this.sendWSMessage(9, data);
    }
}

// 1 connected, with twilio access token & admin username
// 2 accepting calls
// 3 hold, reserved for later use
// 4 get child sid
// 5 caller in queue notification
// 6 dequeue notification