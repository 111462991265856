import axios from "axios";
import router from "../../router/index";

export default {
    login ({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.post('/users/login/', data)
          .then(res => {
            commit('storeAuthData', {
              access: res.data.access,
              refresh: res.data.refresh,
            })
            commit('setAxiosToken');
            commit('storeUserData', res.data)
            commit('unsetUnAuthorisedAlert')
            var to = localStorage.getItem("redirect_to");
            if(to){
              localStorage.removeItem("redirect_to");
              return router.push(to);
            } else {
              return router.push("/dashboard")
            }
          }, error => {

            console.log('Error: ', error)

            if (error.response) {
              console.log('Error Response', error.response);
              reject(error.response)
            } else if (error.request) {
              console.log('Error Request', error.request);
              console.log(error.request);
              reject(error.request)
            } else {
              console.log('Error', error.message);
              reject(error)
            }
            console.log(error.config)

          })
          .catch(error => {
            console.log('Catch', error);
            console.log(error.message);
            console.log(error)
            reject(error)
          });
      });
    },
    autoLogin ({commit}) {
      console.log("AUTO LOGIN")
      const access = localStorage.getItem('accessToken')
      if (!access) {
        return
      }
      const refresh = localStorage.getItem('refreshToken')
      commit('storeAuthData', {
        access: access,
        refresh: refresh,
      });
      commit('setAxiosToken');
    },
    setAxiosToken(){
      var token = localStorage.getItem('accessToken');
      if (token) {
        axios.defaults.headers.common['Authorization'] = 'JWT ' + token;
        axios.interceptors.request.use((config) => {
          store.commit('loading', true);
          return config;
        }, (error) => {
          store.commit('loading', false);
          return Promise.reject(error);
        });
      
        axios.interceptors.response.use((response) => {
          store.commit('loading', false);
          return response;
        }, (error) => {
          store.commit('loading', false);
          return Promise.reject(error);
        });
      } else {
        console.log('No Token')
      }
    },
    logout ({commit}) {
          commit('logoutUser')
          router.replace('/')
    },
    setUnAuthorisedAlert ({commit}) {
          commit('setUnAuthorisedAlert')
          //router.replace('/login')
    },
    codePushRefresh({commit}, data){
      commit('codePushRefresh', data)
    },
    activateCalls ({commit}) {
        commit('activateCalls')
    },
    deactivateCalls ({commit}) {
        commit('deactivateCalls')
    },
}
