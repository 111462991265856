export const CALLCENTRE_OPTIONS = {
    accepting_calls: false,
    agent_status: 'Disconnected',
    call_status: 'Unavailable',
    caller_id: null,
    call_muted: false,
    text_status: 'Waiting',
    sidebar_open: true,
    subscribed_rooms: null,
    br: ' \n ',
    queue_name: null,
    lcd_display: null,
    show_screenpop_modal: false,
    screenpop_data: null, //[{"complaints":[{"id":1,"reference":"bdc91440-721c-4b47-9b37-a8adba34f7aa","description":"test complaint","created":"2021-04-27 23:41:04","go":1}],"policies":[{"id":3,"reference":"SPP03","created":"2021-04-27 23:41:05","go":3},{"id":2,"reference":"SPP02","created":"2021-04-27 23:41:05","go":2},{"id":1,"reference":"SPB01","created":"2021-04-27 23:41:05","go":1}],"customer":{"id":4,"username":"SP404371","name":"Glenn Murley","address":"Merlin's Manor,\n10 Rowan Lane,\nLiskeard,\nCornwall","postcode":"PL14 6FG","dob":"1990-06-26","telephone":"+441579647656","mobile":"+447511996358","work":"","go":4}}],
    screenpop_number: null,
    screenpop_tab: null,
    screenpop_selected_customer: null,
    show_keypad_popover: false,
    number_to_dial: null,
    customer_entered_number: false,
}

export default {
    callcentreData: Object.assign({}, CALLCENTRE_OPTIONS),
}