
export default {
    accessToken: null,
    refreshToken: null,
    unAuthorisedAlert: false,
    logoutAlert: false,
    codePushRefresh: false,
    userid: null,
    user_name: null,
    user_permissions: null,
    alert: null,
    loading: false,
    user: null,
    products: null,
    acceptingCalls: false,
    permissions: null,
    teams: null,
    redirect: "/dashboard",
}

