
export default {
    getGlobalAlert (state) {
      return state.global_alert
    },
    getGlobalAlertType (state) {
      return state.global_alert_type
    },
    getGlobalAlertText (state) {
      return state.global_alert_text
    },
    getGlobalAlertTime (state) {
      return state.global_alert_timer
    },
    getGlobalAlertTimeout (state) {
      return state.global_alert_timeout
    },
    getDashCancel (state) {
      return state.dash_cancel
    }
}

