
export default {
    isAuthenticated(state) {
        return state.accessToken != null
    },
    getAlert (state) {
      return state.alert
    },
    isLoading (state) {
      return state.loading
    },
    getUnAuthorisedAlert (state) {
      return state.unAuthorisedAlert
    }
}

