<template>
  <div>
    <div class="ml-2 mt-5 mb-15">
      <template
        v-if="
          (!obj.hasOwnProperty('hidden') || obj.hidden === false) &&
            (!obj.hasOwnProperty('private') ||
              (obj.private === true && canShowPrivateFields))
        "
      >
        <template v-if="obj.field_type === 'BooleanField'">
          <p v-html="obj.label" class="font-weight-bold"></p>
          <p
            v-html="'(' + obj.sub_label + ')'"
            v-if="obj.sub_label"
            class="font-weight-bold"
          ></p>
          <i
            v-if="obj.data === true"
            class="fas fa-check-circle green icon-large"
          ></i>
          <i v-else class="fas fa-times-circle red icon-large"></i>
          {{ obj.data === true ? "Yes" : "No" }}
        </template>

        <template v-else-if="obj.field_type === 'DateTimeField'">
          <p class="font-weight-bold">
            Time / Date <span v-html="obj.label"></span>
          </p>
          <p
            v-html="'(' + obj.sub_label + ')'"
            v-if="obj.sub_label"
            class="font-weight-bold"
          ></p>
          <p>
            <i class="fas fa-paw"></i
            >{{ moment(obj.data).format("h:mm:ss A, Do MMM YYYY") }}
          </p>
        </template>

        <template v-else-if="obj.field_type === 'DateField'">
          <p v-html="obj.label" class="font-weight-bold"></p>
          <p
            v-html="'(' + obj.sub_label + ')'"
            v-if="obj.sub_label"
            class="font-weight-bold"
          ></p>
          <p>
            <i class="fas fa-paw"></i
            >{{ moment(obj.data).format("Do MMM YYYY") }}
          </p>
        </template>

        <template v-else-if="obj.field_type === 'ChoiceField'">
          <p v-html="obj.label" class="font-weight-bold"></p>
          <p
            v-html="'(' + obj.sub_label + ')'"
            v-if="obj.sub_label"
            class="font-weight-bold"
          ></p>
          <p>
            <i class="fas fa-paw"></i>
            <span v-if="obj.original_data" v-html="obj.original_data"></span>
            <span v-else-if="obj.lookup_choice" v-html="obj.data"></span>
          </p>
        </template>

        <template v-else-if="obj.field_type === 'MultipleChoiceField'">
          <p v-html="obj.label" class="font-weight-bold"></p>
          <p
            v-html="'(' + obj.sub_label + ')'"
            v-if="obj.sub_label"
            class="font-weight-bold"
          ></p>
          <ul>
            <li
              v-for="(inner, inner_key) in obj.original_data"
              :key="'1_' + inner_key"
            >
              <i class="fas fa-paw"></i>{{ inner }}
            </li>
          </ul>
        </template>

        <template v-else>
          <p v-html="obj.label" class="font-weight-bold"></p>
          <p
            v-html="'(' + obj.sub_label + ')'"
            v-if="obj.sub_label"
            class="font-weight-bold"
          ></p>
          <p>
            <i class="fas fa-paw"></i
            ><span v-if="obj.field_type === 'DecimalField'">&pound;</span
            >{{ obj.data }}
          </p>
        </template>
      </template>
    </div>
  </div>
</template>

<script>

export default {
  name: "Portal-Claim-Information-Data",
  props: {
    type: {
      type: String,
      required: true
    },
    obj: {
      type: Object,
      required: true
    }
  },
  computed: {
    canShowPrivateFields() {
      return true;
    }
  }
};
</script>
