// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import './polyfill'
// import cssVars from 'css-vars-ponyfill'
import Vue from 'vue'
import {BootstrapVue, BootstrapVueIcons, BCollapse } from 'bootstrap-vue'
import { BFormDatepicker } from 'bootstrap-vue'
import axios from 'axios';
import twilio from 'twilio-client'
import App from './App'
import router from './router/index'
import store from './store/index'
import Vuelidate from 'vuelidate'
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import VueMoment from 'vue-moment'

import Callcentre from './callcentre'
import BaseInput from './form/components/BaseInput'
import BaseSelect from './form/components/BaseSelect'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
//import { CallCentreDisplay } from './containers/CallCentreDisplay'

Sentry.init({
  dsn: process.env.VUE_APP_SENTRY_URL,
  integrations: [new Integrations.Vue({Vue, attachProps: true})],
});

// todo
// cssVars()


axios.defaults.baseURL = process.env.VUE_APP_ROOT_API_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';
import moment from 'moment'

Vue.prototype.moment = moment


Vue.use(Vuelidate)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueMoment)
Vue.component('b-collapse', BCollapse)
Vue.component('b-form-datepicker', BFormDatepicker)
Vue.component('BaseInput', BaseInput)
Vue.component('BaseSelect', BaseSelect)

import SparkClaimData from "./views/ClaimData";
import SparkClaimDataItem from "./views/ClaimDataItem";

Vue.component("spark-claim-data", SparkClaimData);
Vue.component("spark-claim-data-item", SparkClaimDataItem);

Vue.component('font-awesome-icon', FontAwesomeIcon)
//Vue.config.productionTip = false

Vue.component('jump-claim-button', {
  props: ['claim_id'],
  template: '<b-button @click=\'this.PopClaim({{ claim_id }});\'></b-button>'
})

Vue.component('jump-complaint-button', {
  props: ['claim_id'],
  template: '<b-button @click=\'this.PopClaim({{ claim_id }});\'></b-button>'
})

function ucwords(value) {

  if (!value) return ''
  //if (value.toString() == 'true') return '<span class="fa fa-check"></span>'
  return value.toString().split('_').map(function (item) {
    return item.charAt(0).toUpperCase() + item.substring(1);
  }).join(' ');

}


Vue.filter('capitalize', ucwords);

if (process.env.NODE_ENV === 'development') {
  Vue.config.errorHandler = (err, vm, info) => {
    // err: error trace
    // vm: component in which error occured
    // info: Vue specific error information such as lifecycle hooks, events etc.

    // TODO: Perform any custom logic or log to server
    console.log(err)
    console.log(vm)
    console.log(info)

  };
}

const DEPLOY_VERSION = '1.0'

let current_version = localStorage.getItem('version')

if (current_version) {
  if (current_version !== DEPLOY_VERSION) {
    localStorage.clear()
    localStorage.setItem('version', DEPLOY_VERSION)
  }
} else {
  localStorage.clear()
  localStorage.setItem('version', DEPLOY_VERSION)
}

// Call Centre Functions

function KillCallcentre(){
  if(window.callcentreLaunched){
    window.callcentre.shutdown();
    window.callcentreLaunched = false;
  }
}

function LaunchCallcentre(){
  var token = localStorage.getItem('accessToken');
  if(!window.callcentreLaunched){
    //console.log('Token: ', token)
    if (token) {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token;
      axios({
        url: '/auth/verify/',
        method: 'GET',
      }).then((response) => {

        var options = {
          "debug": true,
          "ws_token": token,
          "ws_url": process.env.VUE_APP_ROOT_WS_URL,
        }
        window.callcentreLaunched = true;
        window.callcentre = new Callcentre(options);
        setTimeout(() => {
          //window.callcentre.connect();
        }, 3000);

      }).catch(error => {
        console.log(error)
        if (error.response.status === 401) {
          store.dispatch('logout');
        }
      });
    }
  }
}

function serve_download(response){
  var url = window.URL.createObjectURL(new Blob([response.data]));
  var link = document.createElement('a');
  link.href = url;
  var regex = /^attachment; filename="([0-9a-zA-Z-_.]+)"$/
  var matches = response.headers["content-disposition"].match(regex);
  var filename = matches[1];
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
}

String.prototype.ucwords = function() {
  var str = this.toLowerCase();
  return str.replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])|(^\(([a-zA-Z\p{M}]))|([ -]\([a-zA-Z\p{M}])/g,
      function($1){
          return $1.toUpperCase();
      });
}

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  //component: CallCentreDisplay,
  //isKeepAlive: true,
  components: {
    App
  },
  mounted() {
    window.callcentreLaunched = false;
    // Code that will run only after the
    // entire view has been rendered
    LaunchCallcentre();

  },
  watch: {
    "$route": (data) => {
      //console.log("ROUTE CHANGE: " + data.name, data)
      if(data.name == "Login"){
        KillCallcentre();
      } else {
        LaunchCallcentre();
      }
    }
  }
})

export default serve_download