import store from "../../store";

export default {
    storeAuthData(state, data) {
      state.accessToken = data.access
      state.refreshToken = data.refresh
      localStorage.setItem('accessToken', state.accessToken)
      localStorage.setItem('refreshToken', state.refreshToken)
    },
    setAxiosToken(state, data) {
    },
    storeUserData(state, data) {
      state.userid = data.user.id
      state.user_name = data.user.username
      state.logoutAlert = false
      state.unAuthorisedAlert = false
      state.user_permissions = data.user.groups
      state.products = data.user.products
      state.permissions = data.user.permissions
      state.teams = data.user.teams
      localStorage.setItem('userid', state.userid)
      localStorage.setItem('user_name', state.user_name)
      localStorage.setItem('user_permissions', JSON.stringify(state.user_permissions))
      localStorage.setItem('products', JSON.stringify(state.products))
      localStorage.setItem('permissions', JSON.stringify(state.permissions))
      localStorage.setItem('teams', JSON.stringify(state.teams))
    },
    userFromLocalToStore(state){
      state.userid = localStorage.getItem('userid')
      state.user_name = localStorage.getItem('user_name')
      if (state.userid) {
        state.user_permissions = JSON.parse(localStorage.getItem('user_permissions'))
        state.products = JSON.parse(localStorage.getItem('products'))
        state.permissions = JSON.parse(localStorage.getItem('permissions'))
        state.teams = JSON.parse(localStorage.getItem('teams'))
      }
    },
    storeUser (state, data) {
      state.user = data
    },
    logoutUser(state) {
      state.accessToken = null
      state.refreshToken = null
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('userid')
      localStorage.removeItem('user_name')
      localStorage.removeItem('user_permissions')
      localStorage.removeItem('products')
      localStorage.removeItem('selected_product')
      localStorage.removeItem('insurers')
      localStorage.removeItem('permissions')
      localStorage.removeItem('teams')
      localStorage.removeItem('redirect_to')
      state.logoutAlert = true
      //localStorage.removeItem('expDate')
    },
    loading(state, loading) {
      console.log('loading: ', loading)
      if (loading) {
        state.loading = true;
      } else {
        state.loading = false
      }
    },
    setUnAuthorisedAlert(state) {
      state.unAuthorisedAlert = true
    },
    codePushRefresh(state, refresh){
      // update state
      state.codePushRefresh = refresh;
      // clear local storage (and hide the logout alert that would appear)
      store.commit('logoutUser')
      state.logoutAlert = false;
      // save the refresh token for this codepush, so we dont get a loop
      localStorage.setItem(refresh, refresh);
      // update state with no refresh needed (default), so we dont get a loop
      state.codePushRefresh = false;
      // get new version
      window.location.reload();
    },
    unsetUnAuthorisedAlert(state) {
      state.unAuthorisedAlert = false
    },
    activateCalls(state) {
      state.acceptingCalls = true
    },
    deactivateCalls(state) {
      state.acceptingCalls = false
    },
}

